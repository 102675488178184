import React, { useContext } from "react";
import { graphql } from "gatsby";
import tw, { styled } from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookSquare,
	faTwitter,
	faInstagram,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { ThemeContext } from "./themes/theme-context";

const Container = tw.div`bg-white`;

const InnerContainer = tw.div`max-w-screen-xl mx-auto`;

const StyledFooter = tw.footer`flex flex-col justify-center text-center px-6 pt-10 pb-20 md:flex-row md:items-center lg:px-20 lg:pt-20 lg:pb-32 max-w-screen-xl`;

const StyledContent = tw.div`w-full md:w-1/2 lg:w-2/3 md:text-left md:pl-3 md:pr-4 lg:pl-6 lg:pr-24`;

const StyledHeadline = tw.h2`font-serif font-normal text-base leading-serif-base mb-3 lg:text-3xl lg:leading-serif-3xl`;

const StyledParagraph = tw.p`font-sans font-normal text-sm leading-sans-sm lg:text-lg lg:leading-sans-lg`;

const StyledInlineLink = styled.a(({ textColor }) => [
	tw`hover:text-black hover:underline cursor-pointer`,
	textColor,
]);

const StyledSocialIcons = tw.ul`flex flex-row justify-center text-center text-xl space-x-7 p-8 mb-2 md:p-0 md:pt-8 md:pl-4 md:text-left lg:p-0 md:justify-start lg:pt-8 lg:px-3 lg:mb-0`;

const StyledSocialIcon = styled.a(({ textHoverColor }) => [
	tw`text-black`,
	textHoverColor,
]);

const StyledLogo = tw.div`w-full md:w-1/2 lg:w-1/3 transition duration-150 ease-linear transform hover:scale-105`;

const StyledLink = tw.a`px-10 lg:px-0 block`;

const Bold = ({ children }) => <strong>{children}</strong>;
const Italic = ({ children }) => <em>{children}</em>;
const Paragraph = ({ children }) => (
	<StyledParagraph>{children}</StyledParagraph>
);

const socialNetworks = {
	Facebook: faFacebookSquare,
	Twitter: faTwitter,
	Instagram: faInstagram,
	YouTube: faYoutube,
};

const Footer = ({ data }) => {
	const node = data.nodes[0];

	const { theme } = useContext(ThemeContext);

	const options = {
		renderMark: {
			[MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
			[MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
			[INLINES.HYPERLINK]: ({ data }, children) => (
				<StyledInlineLink textColor={theme.textColor} href={data.uri}>
					{children}
				</StyledInlineLink>
			),
		},
	};

	return (
		<Container>
			<InnerContainer>
				<StyledFooter>
					<StyledContent>
						<StyledHeadline>{node.headline}</StyledHeadline>
						{renderRichText(node.description, options)}
						<StyledSocialIcons>
							{node.socialIcons.map((item) => {
								return (
									<li key={item.title}>
										<StyledSocialIcon
											href={item.link}
											title={item.title}
											textHoverColor={theme.textHoverColor}
										>
											{socialNetworks[item.title] ? (
												<FontAwesomeIcon
													icon={socialNetworks[item.title]}
													aria-hidden="true"
												/>
											) : (
												""
											)}
											<span tw="sr-only">{item.screenReaderText}</span>
										</StyledSocialIcon>
									</li>
								);
							})}
						</StyledSocialIcons>
					</StyledContent>
					<StyledLogo>
						<StyledLink
							href={node.vibrantLogo.link}
							title="Vibrant Emotional Health"
						>
							<span
								dangerouslySetInnerHTML={{
									__html: node.vibrantLogo.image.svg.content,
								}}
								aria-hidden="true"
							/>
							<span tw="sr-only">{node.vibrantLogo.title}</span>
						</StyledLink>
					</StyledLogo>
				</StyledFooter>
			</InnerContainer>
		</Container>
	);
};

export const footerFragment = graphql`
	fragment footer on ContentfulFooter {
		headline
		description {
			raw
		}
		socialIcons {
			title
			link
			screenReaderText
		}
		vibrantLogo {
			title
			link
			image {
				... on ContentfulAsset {
					svg {
						content
						originalContent
						dataURI
						absolutePath
						relativePath
					}
				}
				file {
					fileName
					url
					contentType
					details {
						image {
							width
							height
						}
					}
				}
			}
		}
	}
`;

export default Footer;
